var player = {};
var isPlaying = false;

async function PlayAudio(name) {
	if (isPlaying) return;
	try {
		isPlaying = true;
		var aplayer = player[name];
		if (aplayer == null) {
			aplayer = new Audio('/audio/' + name + '.mp3');
			//aplayer.load();
			player[name] = aplayer;
		} else {
			aplayer.pause();
			aplayer.currentTime = 0;
		}
		aplayer.muted = false;
		aplayer.play().catch(error => {
			aplayer = null;
			isPlaying = false;
			console.log('音频播放出现问题:', error);
		});
	} catch (ex) {
		console.log(ex);
	} finally {
		isPlaying = false;
	}
}


//经典模式 按键正确
function PlayClick() {
	PlayAudio('click');
}

//经典模式 按键正确
function PlayRight() {
	PlayAudio('right2');
}

//经典模式 按键错误
function PlayErr() {
	PlayAudio('err2');
}

//飞船模式 按键正确
function PlayStrike() {
	PlayAudio('strike');
}

//飞船模式 按键错误
function PlayUnStrike() {
	PlayAudio('unstrike');
}

//敲击模式 按键正确
function PlayHit() {
	PlayAudio('hit');
}

//敲击模式 按键错误
function PlayUnHit() {
	PlayAudio('unhit');
}

//多按了按钮提示
function PlayExcess() {
	PlayAudio('excess');
}


var audioPlayer = null;
var playQueue = null;
var IsPlayList = false;

function getQueueAudio(idx) {
	if (idx < 0 || playQueue == null || idx >= playQueue.length) return null;
	return "/audio/" + playQueue[idx] + ".mp3"
}

function PlayList(man, tag) {
	if (tag == "letter") PlayLeterList(man);
	else if (tag == "adj") {
		PlayAList(man, tag, 50, true);
	} else if (tag == "antonym") {
		PlayAList(man, tag, 100, true);
	} else if (tag == "idiom") {
		PlayAList(man, tag, 50, true);
	} else if (tag == "near") {
		PlayAList(man, tag, 100, true);
	} else {
		PlayAList(man, "number", 50, false);
	}
	resume();
}


function PlayLeterList(man) {
	var arr = new Array();
	var name = man ? "man/letter/" : "woman/letter/";
	const tag = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
	for (var i = 0; i < tag.length; i++) {
		arr.push(name + tag[i]);
	}
	PlayAudioList(arr, false);
}

function PlayAList(man, tag, maxnum, hasPrefix) {
	var arr = new Array();
	var name = man ? "man/" : "woman/";
	if (hasPrefix) {
		arr.push(name + tag + "/0");
	}
	for (var i = 1; i < maxnum; i++) {
		arr.push(name + tag + "/" + i);
	}
	PlayAudioList(arr, hasPrefix);
}


function PlayAudioList(list, hasPrefix) {
	try {
		IsPlayList = false;
		playQueue = new Array();
		if (!hasPrefix) {
			playQueue.push(getRandomData(list));
		}
		for (var i = 0; i < list.length; i++) {
			playQueue.push(list[i]);
		}
		initPlayer();
	} catch (ex) {
		console.log(ex);
	} finally {
		IsPlayList = true;
	}
}


function initPlayer() {
	try {
		if (audioPlayer == null) {
			audioPlayer = new Audio(getQueueAudio(0));
			audioPlayer.play().catch(error => {
				console.error('音频播放出现问题:', error);
			});

			audioPlayer.addEventListener('ended', () => {
				if (IsPlayList && playQueue != null) {
					let waitTime = getRandomIdx(500, 3000);
					setTimeout(() => {
						if (!IsPlayList) return;
						let playId = getRandomIdx(1, playQueue.length);
						loadPlayIdx(playId);
					}, waitTime);
				}
			});
			audioPlayer.muted = false;
			audioPlayer.play().catch(error => {
				audioPlayer == null;
				console.log('音频播放出现问题:', error);
			});
		} else {
			audioPlayer.muted = false;
			loadPlayIdx(0);
		}
	} catch (ex) {
		console.log(ex);
	}

}

function loadPlayIdx(idx) {
	try {
		if (audioPlayer == null) initPlayer();
		audioPlayer.pause();
		audioPlayer.currentTime = 0;
		audioPlayer.src = getQueueAudio(idx);
		audioPlayer.load();
		audioPlayer.play().catch(error => {
			audioPlayer == null;
			console.log('音频播放出现问题:', error);
		});
	} catch (ex) {
		console.log(ex);
	}
}

function stopAll() {
	try {
		IsPlayList = false;
		playQueue = null;
		if (audioPlayer) {
			audioPlayer.pause();
			audioPlayer.currentTime = 0;
		}
	} catch (ex) {
		console.log(ex);
	}
}

function getRandomData(list) {
	let len = list.length;
	return list[Math.floor(Math.random() * len) % len];
}

function getRandomIdx(minnum, maxnum) {
	return minnum + Math.floor(Math.random() * new Date().getTime()) % (maxnum - minnum);
}

function pause() {
	if (audioPlayer) {
		audioPlayer.muted = true;
	}
}

function resume() {
	if (audioPlayer) {
		audioPlayer.muted = false;
	}
}

export default {
	PlayAudio,

	PlayClick,
	PlayRight,
	PlayErr,
	PlayStrike,
	PlayUnStrike,
	PlayHit,
	PlayUnHit,

	PlayExcess, //多按了按钮

	PlayList,
	stopAll,
	pause,
	resume
};