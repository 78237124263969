const axios = require("axios");
const encode = require("./base/encode.js");
const local = require("./local.js");

const isDebug = false;

const appsvrURL = "https://htsapi.jssgkj.cn";
// const appTestURL = "http://apitest.jssgkj.cn:8219";
const appTestURL = "http://localhost:8219";

const instance = axios.create({
	dataType: "application/json",
	crossDomain: true,
	cache: false,
	baseURL: isDebug ? appTestURL : appsvrURL,
	timeout: 20000
});


function getLoading(that) {
	return that.$loading({
		lock: true,
		text: "加载中，请稍候...",
		spinner: 'el-icon-loading',
		background: 'rgba(0, 0, 0, 0.7)'
	});
}

function HttpGet(that, rawurl, success, fail, needload) {
	let loadings = needload ? getLoading(that) : null;
	let url = rawurl;
	if (url.indexOf("/api/dev/reg") < 0 &&
		url.indexOf("/api/my/checkjump") < 0) {
		let uid = local.getUserId();
		let devId = local.getDeviceId();
		let orgId = local.getOrgId();
		url += (url.indexOf("?") > 0) ? "&" : "?";
		url += "uid=" + uid + "&dev=" + devId + "&org=" + orgId;
	}

	url = encode.getVerifyUrl(url);
	instance
		.get(url)
		.then(function(response) {
			decodeResponse(that, response, success, fail);
		})
		.catch(function(error) {
			if (fail != null) fail(-1, error.message);
			else that.$message.error(error.message);
		})
		.then(function() {
			if (loadings != null) loadings.close();
		});
}

function HttpPost(that, url, rawparams, success, fail, needload) {
	let loadings = needload ? getLoading(that) : null;
	var newparams = {
		uid: local.getUserId(),
		dev: local.getDeviceId(),
		org: local.getOrgId(),
		data: rawparams
	};
	let params = encode.getVerifyForm(newparams);
	instance
		.post(url, params)
		.then(function(response) {
			decodeResponse(that, response, success, fail);
		})
		.catch(function(error) {
			if (fail != null) fail(-1, error.message);
			else that.$message.error(error.message);
		})
		.then(function() {
			if (loadings != null) loadings.close();
		});
}

function decodeResponse(that, response, success, fail) {
	try {
		if (response == null) {
			if (fail != null) fail(-1, "服务器无响应");
			return;
		}
		let res = response.data;
		if (res == null) {
			if (fail != null) fail(-1, "服务器响应数据错误");
			return;
		}
		if (res.ret == 0) {
			if (success != null) success(res.data);
			else that.$message.info(res.data);
		} else if (res.ret < 0) {
			that.$message.error(res.errmsg);
			that.$router.push('/login');
		} else {
			if (fail != null) fail(res.ret, res.errmsg);
			else that.$message.error(res.errmsg);
		}

	} catch (error) {
		if (fail != null) fail(-1, error);
		else that.$message.error(error);
	}
}


//1、注册设备信息
function registerDevice(that, success) {
	let devTag = local.getDeviceTag();
	let devId = local.getDeviceId();

	let url = "/api/dev/reg?dev=" + devTag;
	if (devId != null) {
		url += "&id=" + devId;
	}
	HttpGet(
		that,
		url,
		(id) => {
			local.setDeviceId(id);
			if (success != null) success(id);
		},
		null,
		false
	);
}


//2、用户登录
// withpwd :  true 表示使用密码登录，  false 表示使用验证码登录
// loginType,  0；患者登录   1：医生或管理员登录
function userLogin(that, loginType, withpwd, userName, password, success) {
	registerDevice(that, (devId) => {
		let url = withpwd ? "/api/user/login" : "/api/user/smslogin";
		let params = {
			userName: userName,
			password: password,
			loginType: loginType
		};
		HttpPost(that, url, params, (userInfo) => {
			local.setUserInfo(userInfo);
			if (success != null) success(userInfo);
		}, null, true);
	});
}


//3、发送短消息
//type:   signin 登录 ，signup注册， password忘记密码， auth: 授权验证  phone：修改手机号码
function sendSMS(that, type, phone, success) {
	let url = "/api/sms/send?type=" + type + "&phone=" + phone;
	HttpGet(that, url, success, null, false);
}


//4、用户登录页面忘记密码
function forgetPassword(that, phone, code, password, success) {
	let url = "/api/my/password?phone=" + phone + "&code=" + code + "&password=" + password;
	HttpGet(that, url, success, null, false);
}

// 获取患者每日任务
function getMyDailyTask(that, success) {
	let url = "/api/task/patient";
	HttpGet(that, url, success, null, false);
}


// 获取患者每日任务中单个任务细节
function getMyTaskDetail(that, uTaskId, success, fail) {
	let url = "/api/task/detail?task=" + uTaskId;
	HttpGet(that, url, success, fail, false);
}

// 启动项目
// var params = {
// 	uSltId: 10101,
// 	uTaskId: 10001,
// 	gameId: 1,
// 	gameName: "跟随",
// 	params: {
// 		"noises": true,
// 		"speaker": "男",
// 		"content": "成语2",
// 		"speed": 1.0,
// 		"size": 3.0
// 	}
// };
function startPlayGame(that, params, success) {
	let url = "/api/task/start";
	HttpPost(that, url, params, success, null, true);
}

// 结束项目
// var params = {
// 	execId: 10001,
// 	execInfo: [{
// 			id: "accuracy",
// 			name: "准确率",
// 			value: 0.927
// 		},
// 		{
// 			id: "feedback",
// 			name: "反馈",
// 			value: 0.82
// 		}
// 	],
// 	status: 1 //1:时间已够，正常结束 2：中途退出
// };

function endPlayGame(that, params, success, fail) {
	let url = "/api/task/end";
	HttpPost(that, url, params, success, fail, true);
}

//获取混合图片
// var params = {
// 	screen: {
// 		realWidth: 48.8, //cm
// 		realHeight: 36.58, //cm
// 		width: 1920,
// 		height: 1080,
// 		viewDistance: 40
// 	},
// 	mode: 0, //0：经典模式，1 敲击或飞船模式
// 	id: null, //不填为随机， mode=0：取值 0-19  mode=1：取值 0-24
// 	size: 480, //大尺寸 480  小尺寸 360
// 	bo: 0, //水平 BO 方向
// 	bd: 0, //垂直 BD 方向
// 	movex: null, //可不填，水平固定偏移量
// 	movey: null //可不填，垂直固定偏移量
// }

var loadingImage = false;
var imageOpts = null;

function getImage(that, params, success) {
	imageOpts = JSON.parse(JSON.stringify(params));
	if (loadingImage) {
		//console.log("bypass:" + params);
		return;
	}
	loadingImage = true;
	try {
		let url = "/api/image/get?t=" + Date.now();
		HttpPost(that, url, params, (response) => {
			// 成功回调
			if (success) {
				success(response);
			}
			loadingImage = false;
		}, (code, message) => {
			// 错误回调
			that.$message.error(message);
			if (success) {
				success();
			}
			loadingImage = false;
		}, false);
	} catch (error) {
		//console.error("请求过程中发生错误:", error);
		that.$message.error("请求过程中发生错误，请稍后重试。");
		loadingImage = false;
	}
}



//37. 获取患者试玩统计数据
// startDate,endDate 允许为空， 格式： 2001-01-01
//withTrial - true: 统计数据包括试玩项目数据  false: 统计不包括试玩项目数据
//statByDay - true: 按照每日汇总统计  false：不汇总，每次数据
function getPatientGameStat(that, gameId, withTrial, statByDay,
	startDate, endDate, success) {
	let url = "/api/patient/stat?game=" + gameId;
	if (withTrial) url += "&trial=1";
	if (statByDay) url += "&day=1";
	if (startDate != null) url += "&start=" + startDate;
	if (endDate != null) url += "&end=" + endDate;
	HttpGet(that, url, success, null, false);
}

//34. 获取用户或企业自身账单列表
// isExpend true或false true为支出明细  false为收入明细
function getMyAccountList(that, isExpend, page, count, success) {
	let url = "/api/my/account";
	url += "?ex=" + (isExpend ? 1 : 0);
	url += "&page=" + page + "&size=" + count;
	HttpGet(that, url, success, null, true);
}

//47. 获取用户自身信息
function getMyUserInfo(that, success) {
	let url = "/api/my/userinfo";
	HttpGet(that, url, success, null, false);
}

//49. 更改用户自身密码
//通过旧密码，更改新密码，无需手机短信验证
function changeMyPassword(that, oldpass, newpass, success) {
	let url = "/api/user/password";
	let params = {
		oldpass: oldpass,
		newpass: newpass
	};
	HttpPost(that, url, params, success, null, true);
}

//52、变更手机号码
function changeLineNumber(that, phone, code, success) {
	let url = "/api/my/change?phone=" + phone + "&code=" + code;
	HttpGet(that, url, success, null, false);
}

//55. 注册跳转信息
function registerJump(that, success) {
	let url = "/api/my/jump";
	HttpGet(that, url, success, null, true);
}

//56.检查是否免登陆
function checkSilenceLogin(that, code, success) {
	if (code == null || code.length < 36) return;
	registerDevice(that, (devId) => {
		let url = "/api/my/checkjump?code=" + code + "&dev=" + devId;
		HttpGet(that, url, (res) => {
			if (res.isDemo) {
				local.setUserInfo(res, code);
				success(res);
			}
		}, null, true);
	});
}

//7、修改用户信息
function modifyUser(that, detailInfo, success) {
	registerDevice(that, (devId) => {
		let url = "/api/user/changemy";
		let params = {
			detailInfo: detailInfo
		};
		HttpPost(that, url, params, success, null, true);
	});
}

export default {
	local,
	isDebug,
	HttpGet,
	HttpPost,

	registerDevice,
	userLogin,
	sendSMS,
	forgetPassword,

	getMyDailyTask,
	getMyTaskDetail,

	startPlayGame,
	endPlayGame,
	getImage,

	getPatientGameStat,
	getMyUserInfo,
	getMyAccountList,
	changeLineNumber,
	changeMyPassword,
	checkSilenceLogin,
	registerJump,
	modifyUser
};
