<template>
	<el-dialog :title="title" :visible.sync="Visible" :append-to-body="true" @close="closeIt" width="27%">
		<el-form ref="form">
			<div class="fg"></div>
			<div class="table">
				<div class="tc w30p center">屏幕宽度
				</div>
				<div class="tc w50p">
					<el-input type="number" placeholder="请输入实际测量值" v-model="form.width"></el-input>
				</div>
				<div class="tc w20p center">厘米</div>
			</div>
			<div class="fg"></div>
			<div class="table">
				<div class="tc w30p center">屏幕高度
				</div>
				<div class="tc w50p">
					<el-input type="number" placeholder="请输入实际测量值" v-model="form.height"></el-input>
				</div>
				<div class="tc w20p center">厘米</div>
			</div>
		</el-form>
		<span slot="footer" class="dialog-footer">
			<el-button @click="closeIt">取消</el-button>
			<span class="space"></span>
			<el-button type="primary" @click="saveIt">确定</el-button>
			<span class="space"></span>
		</span>
	</el-dialog>
</template>

<script>
	export default {
		data() {
			return {
				title: '调整屏幕尺寸',
				Visible: false,
				form: {
					width: 48.8,
					height: 36.58
				}
			};
		},
		computed: {

		},
		watch: {},

		created() {},
		mounted() {},

		methods: {
			init: function(res) {
				this.Visible = true;
				if (res != null) {
					this.form = res;
				}
			},

			saveIt: function() {
				if (this.form.width == null || this.form.width < 10 || this.form.width > 200) {
					this.$message.error("请输入有效屏幕宽度");
					return;
				}
				if (this.form.height == null || this.form.height < 10 || this.form.height > 500) {
					this.$message.error("请输入有效屏幕高度");
					return;
				}
				this.$local.setScreenSize(this.form);
				this.Visible = false;
				this.$message.success('调整屏幕尺寸成功！重新进入项目训练后参数将生效。');
			},

			closeIt: function() {
				this.Visible = false;
				this.$emit("close", null);
			}
		}
	};
</script>

<style scoped>
	@import url(../css/table.css);
</style>